<template>
  <v-container>
    <!-- page loading -->
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a whiles.
        </h3>
      </v-col>
    </v-row>

    <!-- notification -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      color="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
      dismissible
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="9">
        <!-- header -->
        <v-row>
          <v-col>
            <h1>Funded Mortgages</h1>

            <p class="grey--text">
              In this page you can view all the funded mortgages. You can also
              search by province (e.g. AB for Alberta, BC for British Columbia
              and ON for Ontario) or client name and click on the more info
              button to view the full details of the funded mortgage.
            </p>
          </v-col>
        </v-row>

        <!-- search -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="search_value"
              label="Search"
              outlined
              filled
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>

        <v-row>
          <!-- filter -->
          <v-col>
            <p>Filter by Province:</p>

            <v-select
              v-model="filter_province"
              :items="province_options"
              label=" Select Province "
              solo
              multiple
            />
          </v-col>

          <v-col>
            <p>Filter by Fiscal Year:</p>
            <v-select
              v-model="filter_fiscal_year"
              :items="fiscal_year_options"
              label=" Select Fiscal Year "
              solo
              multiple
            />
          </v-col>

          <!-- sort -->
          <v-col>
            <p>Sort by Actual Fund Date:</p>
            <v-select
              v-model="sort_by_actual_fund_date"
              :items="sort_options"
              label=" Select Order "
              @change="sort_by_maturity_date = null"
              solo
            />
          </v-col>

          <v-col>
            <p>Sort by Maturity Date:</p>
            <v-select
              v-model="sort_by_maturity_date"
              :items="sort_options"
              label=" Select Order "
              @change="sort_by_actual_fund_date = null"
              solo
            />
          </v-col>

          <!-- clear filters button -->
          <v-col class="d-flex align-center">
            <v-btn
              color="warning"
              text
              @click="
                search_value = '';
                filter_province = '';
                filter_fiscal_year = '';
                sort_by_actual_fund_date = null;
                sort_by_maturity_date = null;
              "
            >
              Clear Filters
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3 v-if="fund_morts.length === 0" class="text-center grey--text">
              No Records Found!
            </h3>
            <v-expansion-panels multiple :value="expandedPanels">
              <v-expansion-panel
                class="bordered"
                v-for="(item, index) in filteredFundedMortgages"
                :key="index"
              >
                <v-expansion-panel-header>
                  {{
                    item.int_custs.find((int_cust) => int_cust.main_applicant)
                      .cust_snap.last_name
                  }}
                  ,
                  {{
                    item.int_custs.find((int_cust) => int_cust.main_applicant)
                      .cust_snap.first_name
                  }}
                  - {{ item.deal.province | province }} -
                  {{ item.actual_fund_date }}
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-card flat class="mt-5">
                    <v-card-text>
                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Maturity Date</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p>{{ item.mort_maturity_date | formatDate }}</p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Province</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p>{{ item.deal.province | province }}</p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Properties</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p
                            v-for="int_prop in item.int_props"
                            :key="int_prop.id"
                          >
                            {{
                              int_prop.subject
                                ? `${int_prop.prop_snap.address.street_number}, ${int_prop.prop_snap.address.street_type}, ${int_prop.prop_snap.address.street_direction}, ${int_prop.prop_snap.address.unit}, ${int_prop.prop_snap.address.postal_code}, ${int_prop.prop_snap.address.street_name}, ${int_prop.prop_snap.address.city}, ${int_prop.prop_snap.address.province}`
                                : ""
                            }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Mortgage Position</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p>
                            {{ item.mortgage_position | mortgagePosition }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Mortgage Amount</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p>
                            {{ item.value | currency }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Fiscal Year</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p>
                            {{ item.fiscal_year }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-divider />

                      <v-row class="mt-2">
                        <v-col cols="3">
                          <p>Accounts</p>
                        </v-col>
                        <v-col cols="1"> : </v-col>
                        <v-col>
                          <p
                            v-if="item.int_accounts.length === 0"
                            class="grey--text"
                          >
                            No accounts found!
                          </p>

                          <v-row v-if="item.int_accounts.length > 0">
                            <v-col>
                              <v-simple-table
                                class="mb-5"
                                style="background-color: #111"
                              >
                                <thead class="text-caption">
                                  <tr>
                                    <th>Company Name</th>

                                    <th>Institution</th>

                                    <th>Type</th>

                                    <th>Corporation Name</th>

                                    <th>Account Number</th>

                                    <th :width="150">Percentage</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr
                                    v-for="(
                                      int_account, index
                                    ) in item.int_accounts"
                                    :key="int_account.account.id"
                                  >
                                    <td>
                                      {{ int_account.account.company.name }}
                                    </td>

                                    <td>
                                      {{ int_account.account.institution }}
                                    </td>

                                    <td>
                                      {{ int_account.account.type }}
                                    </td>

                                    <td>
                                      {{ int_account.account.corporation_name }}
                                    </td>

                                    <td>
                                      {{ int_account.account.account_number }}
                                    </td>

                                    <td>{{ int_account.percentage }}%</td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $router.push(
                            `/dashboard/admin/funded_mortgage/read/${item.id}`
                          )
                        "
                      >
                        more info
                        <v-icon class="ml-2">
                          mdi-information-variant-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filters from "@/plugins/filters";
import API from "@/plugins/API";

export default {
  name: "ListFundedMortgage",

  data: () => ({
    loading: false,
    error: false,
    errorMessage: "",
    search_value: null,
    filter_province: [],
    filter_fiscal_year: [],
    sort_by_actual_fund_date: null,
    sort_by_maturity_date: null,
    sort_options: [
      { text: "Ascending", value: "asc" },
      { text: "Descending", value: "desc" },
    ],
    province_options: [
      { text: "Alberta", value: "AB" },
      { text: "British Columbia", value: "BC" },
      { text: "Ontario", value: "ON" },
    ],
    fiscal_year_options: [
      { text: "2017 - 2018", value: "2017 - 2018" },
      { text: "2018 - 2019", value: "2018 - 2019" },
      { text: "2019 - 2020", value: "2019 - 2020" },
      { text: "2020 - 2021", value: "2020 - 2021" },
      { text: "2021 - 2022", value: "2021 - 2022" },
      { text: "2022 - 2023", value: "2022 - 2023" },
      { text: "2023 - 2024", value: "2023 - 2024" },
      { text: "2024 - 2025", value: "2024 - 2025" },
    ],

    fund_morts: [],

    search: "",
  }),

  computed: {
    expandedPanels() {
      if (this.fund_morts) return this.fund_morts.map((item, index) => index);
      else return 0;
    },

    filteredFundedMortgages() {
      let filtered = this.fund_morts;

      if (this.search_value) {
        const search = this.search_value.toLowerCase();
        filtered = filtered.filter((item) => {
          const firstName = item.int_custs
            .find((int_cust) => int_cust.main_applicant)
            .cust_snap.first_name.toLowerCase();
          const lastName = item.int_custs
            .find((int_cust) => int_cust.main_applicant)
            .cust_snap.last_name.toLowerCase();
          const province = item.deal.province.toLowerCase();
          return (
            firstName.includes(search) ||
            lastName.includes(search) ||
            province.includes(search)
          );
        });
      }

      if (this.filter_province.length > 0) {
        filtered = filtered.filter((item) => {
          return this.filter_province.includes(item.deal.province);
        });
      }

      if (this.filter_fiscal_year.length > 0) {
        filtered = filtered.filter((item) => {
          return this.filter_fiscal_year.includes(item.fiscal_year);
        });
      }

      if (
        !this.search_value &&
        this.filter_province.length === 0 &&
        this.filter_fiscal_year.length === 0 &&
        !this.sort_by_actual_fund_date &&
        !this.sort_by_maturity_date
      ) {
        filtered = this.fund_morts.filter((item, index) => index < 10);
        return this.sortFundedMortgagesByFiscalYear(filtered);
      }

      return this.sortFundedMortgages(filtered);
    },
  },

  methods: {
    async get_data() {
      try {
        this.loading = true;

        const response = await API().get(
          "api/funded-mortgage/read_funded_mortgages"
        );
        // console.log(response);
        this.fund_morts = response.data;

        this.loading = false;
      } catch (error) {
        this.errorMessage = error.message;
        this.error = true;
        this.loading = false;
      }
    },

    sortFundedMortgages(mortgages) {
      if (this.sort_by_actual_fund_date) {
        if (this.sort_by_actual_fund_date === "asc") {
          return mortgages.sort((a, b) => {
            return new Date(a.actual_fund_date) - new Date(b.actual_fund_date);
          });
        } else if (this.sort_by_actual_fund_date === "desc") {
          return mortgages.sort((a, b) => {
            return new Date(b.actual_fund_date) - new Date(a.actual_fund_date);
          });
        } else {
          return mortgages;
        }
      }

      if (this.sort_by_maturity_date) {
        if (this.sort_by_maturity_date === "asc") {
          return mortgages.sort((a, b) => {
            return (
              new Date(a.mort_maturity_date) - new Date(b.mort_maturity_date)
            );
          });
        } else if (this.sort_by_maturity_date === "desc") {
          return mortgages.sort((a, b) => {
            return (
              new Date(b.mort_maturity_date) - new Date(a.mort_maturity_date)
            );
          });
        } else {
          return mortgages;
        }
      }
    },

    sortFundedMortgagesByFiscalYear(mortgages) {
      return mortgages.sort((a, b) => {
        const aYear = parseInt(a.fiscal_year.split("-")[1]);
        const bYear = parseInt(b.fiscal_year.split("-")[1]);
        return bYear - aYear;
      });
    },
  },

  filters: {
    ...filters,
  },

  mounted() {
    this.get_data();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
